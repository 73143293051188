define('ember-debounced-input-helpers/mixins/debounced-key', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var run = _ember['default'].run;
  var isEqual = _ember['default'].isEqual;
  var observer = _ember['default'].observer;
  var addObserver = _ember['default'].addObserver;
  var removeObserver = _ember['default'].removeObserver;

  exports['default'] = _ember['default'].Mixin.create({
    /**
     * Number of milliseconds to wait.
     * @type {Number}
     */
    wait: 500,

    /**
     * Trigger the function on the leading instead of the trailing edge of the wait interval. Defaults to false.
     * @type {Boolean}
     */
    immediate: false,

    /**
     * Usually debounced properties are one way, if you plan to manually update val, this will keep val and value in sync
     * @type {Boolean}
     */
    sync: false,

    /**
     * Bound value to be debounced
     */
    val: null,

    /**
     * Raw value
     */
    value: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('value', this.get('val'));
      if (this.sync) {
        addObserver(this, 'val', this, this._sync);
      }
    },

    onValueChange: observer('value', function () {
      this._valuePid = run.debounce(this, this._setVal, this.wait, this.immediate);
    }),

    _sync: function _sync() {
      if (!this.isDestroying && !this.isDestroyed && !isEqual(this.get('val'), this.get('value'))) {
        this.set('value', this.get('val'));
      }
    },

    _setVal: function _setVal() {
      if (!this.isDestroying && !this.isDestroyed) {
        this.set('val', this.get('value'));
      }
    },

    /**
     * Cleanup by canceling any current debounce
     */
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      run.cancel(this._valuePid);
      if (this.sync) {
        removeObserver(this, 'val', this, this._sync);
      }
    }
  });
});