(function(global) {
    if (!('HTMLCanvasElement' in global)) return;
    if (!('toDataURL' in global.HTMLCanvasElement.prototype)) return;
    if ('toBlob' in global.HTMLCanvasElement.prototype) return;
    Object.defineProperty(global.HTMLCanvasElement.prototype, 'toBlob', {
        value: function(callback/*, type, encoderOptions*/) {
            var url = this.toDataURL.apply(this, [].slice.call(arguments, 1));
            var m = /^data:(.*?);base64,(.*)$/.exec(url), type = m[1], b64 = m[2];
            setTimeout(function() {
                callback(new Blob([
                    new Uint8Array(global.atob(b64).split('').map(function(c) {
                        return c.charCodeAt(0);
                    }))], {type: type}));
            }, 0);
        }, writable: true, enumerable: true, configurable: true});
}(self));
