define('ember-cli-tooltipster/components/tool-tipster', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({

    attributeBindings: ['title'],

    updateTitle: _ember['default'].observer('title', function () {
      var _this = this;

      _ember['default'].run.schedule('afterRender', this, function () {
        _this.$().tooltipster('content', _this.get('title'));
      });
    }),

    updateContent: _ember['default'].observer('content', function () {
      var _this2 = this;

      _ember['default'].run.schedule('afterRender', this, function () {
        _this2.$().tooltipster('content', _this2.get('content'));
      });
    }),

    /**
     * Hide tooltip manually.
     * Send action `onTooltipHide` when the tooltip is fully closed.
     *
     * Please note that if the show/hide action is somehow cancelled before it has completed its animation,
     * the callback function will never be called.
     */
    hideTooltip: _ember['default'].observer('hide', function () {
      var _this3 = this;

      var hide = this.get('hide');
      if (hide) {
        _ember['default'].run.once(this, function () {
          _this3.$().tooltipster('hide', function () {
            _this3.sendAction('onTooltipHide');
          });
        });
      }
    }),

    /**
     * Show tooltip manually.
     * Send action `onTooltipShow` when the tooltip is fully open.
     *
     * Please note that if the show/hide action is somehow cancelled before it has completed its animation,
     * the callback function will never be called.
     */
    showTooltip: _ember['default'].observer('show', function () {
      var _this4 = this;

      var show = this.get('show');
      if (show) {
        _ember['default'].run.once(this, function () {
          _this4.$().tooltipster('show', function () {
            _this4.sendAction('onTooltipShow');
          });
        });
      }
    }),

    /**
     * Set how tooltips should be activated and closed.
     * Default: 'hover'
     * Options: [hover, click]
     * @type {String}
     */
    triggerEvent: 'hover',

    tooltipsterOptions: ['animation', 'arrow', 'arrowColor', 'content', 'contentAsHTML', 'debug', 'delay', 'interactive', 'minWidth', 'maxWidth', 'offsetX', 'offsetY', 'position', 'positionTracker', 'speed', 'timer', 'theme', 'updateAnimation', 'autoClose', 'icon', 'iconCloning', 'iconDesktop', 'iconTouch', 'iconTheme'],

    _initializeTooltipster: _ember['default'].on('didInsertElement', function () {
      var _this5 = this;

      var options = {};

      this.get('tooltipsterOptions').forEach(function (item) {
        if (!_ember['default'].isEmpty(_this5.get(item))) {
          options[item] = _this5.get(item);
        }
      });

      options.trigger = this.get('triggerEvent');
      options.functionInit = _ember['default'].$.proxy(this.functionInit, this);
      options.functionBefore = _ember['default'].$.proxy(this.functionBefore, this);
      options.functionReady = _ember['default'].$.proxy(this.functionReady, this);
      options.functionAfter = _ember['default'].$.proxy(this.functionAfter, this);
      options.positionTrackerCallback = _ember['default'].$.proxy(this.positionTrackerCallback, this);

      this.$().tooltipster(options);
    }),

    _destroyTooltipster: _ember['default'].on('willDestroyElement', function () {
      if (this.$().data('tooltipster-ns')) {
        this.$().tooltipster('destroy');
      }
    }),

    /**
     * Send action `open` when the tooltip and its contents have been added to the DOM
     *
     * @method functionBefore
     */
    functionReady: function functionReady(origin, tooltip) {
      this.sendAction('open', tooltip);
    },

    /**
     * Send action `close` once the tooltip has been closed and removed from the DOM
     *
     * @method functionBefore
     */
    functionAfter: function functionAfter() /*origin*/{
      this.sendAction('close');
    }
  });
});