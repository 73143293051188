define('ember-cli-rx/helpers/observable', ['exports'], function (exports) {
  /* globals Ember */

  /**
    Creates a property that accepts Rx.Observables
    and returns a single observable that represents the latest observable
    passed.
  
    This can be used for any input to a component that is an observable.
  
    @method observable
    @return Ember.ComputedProperty
  */
  'use strict';

  exports['default'] = observable;

  function observable() {
    return Ember.computed(function (key, val) {
      var backingField = '_' + key;
      if (!this[backingField]) {
        this[backingField] = new Rx.BehaviorSubject(Rx.Observable.empty());
      }

      if (arguments.length > 1) {
        var next = val && val instanceof Rx.Observable ? val : Rx.Observable.empty();
        this[backingField].onNext(next);
      }

      return this[backingField]['switch']();
    });
  }
});