define('ember-date-components/helpers/is-equal-time', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  exports.isEqualTime = isEqualTime;

  var Helper = _ember['default'].Helper;
  var getTypeOf = _ember['default'].typeOf;
  var isMoment = _moment['default'].isMoment;

  function isEqualTime(_ref) {
    var _ref2 = _slicedToArray(_ref, 2);

    var d1 = _ref2[0];
    var d2 = _ref2[1];

    if (!isMoment(d1) || !isMoment(d2)) {
      return false;
    }

    return d1.format('HH:mm') === d2.format('HH:mm');
  }

  exports['default'] = Helper.helper(isEqualTime);
});