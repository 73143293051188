define('ember-perfect-scroll/components/perfect-scroll/component', ['exports', 'ember', 'ember-perfect-scroll/components/perfect-scroll/template'], function (exports, _ember, _emberPerfectScrollComponentsPerfectScrollTemplate) {
  'use strict';

  var $ = _ember['default'].$;
  var _get = _ember['default'].get;
  var set = _ember['default'].set;
  var run = _ember['default'].run;
  var isPresent = _ember['default'].isPresent;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var guidFor = _ember['default'].guidFor;

  // Perfect Scrollbar scroll events
  var psEvents = ['ps-scroll-y', 'ps-scroll-x', 'ps-scroll-up', 'ps-scroll-down', 'ps-scroll-left', 'ps-scroll-right', 'ps-y-reach-start', 'ps-y-reach-end', 'ps-x-reach-start', 'ps-x-reach-end'];

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberPerfectScrollComponentsPerfectScrollTemplate['default'],

    // Internal id for element
    scrollId: null,

    // Perfect scrollbar related settings
    wheelSpeed: 1,
    wheelPropagation: false,
    swipePropagation: true,
    minScrollbarLength: null,
    maxScrollbarLength: null,
    useBothWheelAxes: false,
    useKeyboard: true,
    suppressScrollX: false,
    suppressScrollY: false,
    scrollTop: 0,
    scrollLeft: 0,
    scrollXMarginOffset: 0,
    scrollYMarginOffset: 0,
    includePadding: false,
    exceptions: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      run.schedule('afterRender', function () {
        window.Ps.initialize($('#' + _get(_this, 'eId'))[0], _this._getOptions());
        _this.bindEvents();
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var element = document.getElementById(_get(this, 'eId'));

      if (element) {
        window.Ps.destroy(element);
      }

      this.unbindEvents();
    },

    eId: computed('scrollId', {
      get: function get() {
        if (isEmpty(_get(this, 'scrollId'))) {
          set(this, 'scrollId', 'perfect-scroll-' + guidFor(this));
        }

        return _get(this, 'scrollId');
      }
    }).readOnly(),

    /**
     * Binds perfect-scrollbar events to function
     * and then calls related events if user gives the action
     */
    bindEvents: function bindEvents() {
      var _this2 = this;

      var self = this;
      var mapping = {};
      var el = $(document.getElementById(_get(this, 'eId')));

      psEvents.map(function (evt) {
        mapping[evt] = function () {
          self.callEvent(evt);
        };

        $(el).on(evt, mapping[evt].bind(_this2));
      });

      set(this, 'mapping', mapping);
    },

    /**
     * Calls perfect-scrollbar
     * @param  {String} evt perfect-scrollbar event name
     */
    callEvent: function callEvent(evt) {
      if (isPresent(_get(this, evt))) {
        this.sendAction(evt);
      }
    },

    /**
     * Unbinds all event listeners
     */
    unbindEvents: function unbindEvents() {
      var _this3 = this;

      var mapping = _get(this, 'mapping');
      var el = $(document.getElementById(_get(this, 'eId')));

      psEvents.map(function (evt) {
        $(el).off(evt, run.cancel(_this3, mapping[evt].bind(_this3)));
      });
    },

    _getOptions: function _getOptions() {
      return {
        wheelSpeed: _get(this, 'wheelSpeed'),
        wheelPropagation: _get(this, 'wheelPropagation'),
        swipePropagation: _get(this, 'swipePropagation'),
        minScrollbarLength: _get(this, 'minScrollbarLength'),
        maxScrollbarLength: _get(this, 'maxScrollbarLength'),
        useBothWheelAxes: _get(this, 'useBothWheelAxes'),
        useKeyboard: _get(this, 'useKeyboard'),
        suppressScrollX: _get(this, 'suppressScrollX'),
        suppressScrollY: _get(this, 'suppressScrollY'),
        scrollXMarginOffset: _get(this, 'scrollXMarginOffset'),
        scrollYMarginOffset: _get(this, 'scrollYMarginOffset'),
        includePadding: _get(this, 'includePadding'),
        scrollTop: _get(this, 'scrollTop'),
        scrollLeft: _get(this, 'scrollLeft'),
        exceptions: _get(this, 'exceptions')
      };
    }
  });
});